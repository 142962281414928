import React, { useRef } from 'react';

import the_enchanted_scribe from '../assets/the_enchanted_scribe.webp';
import dm_timothy from '../assets/dm_timothy.jpg';
import likely_mimics from '../assets/likely_mimics.jpg';

// Import Splide and SplideSlide components
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Import Splide styles
import '@splidejs/splide/css';
import Testimonial from './Testimonial';

const Testimonials = () => {
  const splideRef = useRef(null);

  const handlePrevClick = () => {
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.go('-1');
    }
  };

  const handleNextClick = () => {
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.go('+1');
    }
  };

  return (
    <div className="h-full flex flex-col gap-4">
      <Splide
        ref={splideRef}
        aria-label="Testimonials"
        options={{
          rewind: true,
          perPage: 3,
          perMove: 1,
          gap: '1.5rem',
          pagination: false,
          fixedHeight: '500px',
          arrows: false, // Disable default arrows
          breakpoints: {
            1024: {
              perPage: 1,
              fixedHeight: 'auto',
            },
          },
        }}
        className="h-full"
      >
        <Testimonial
          text="The potential of this tool to enhance the start of each session, prime my players beforehand, and save me so much time is incredible. This project will be a game-changer for many of us in the TTRPG community."
          name="Jax J."
          initials="JJ"
          association="Scrybe User"
        />

        <Testimonial
          text="The option to proofread the summary is awesome. Awesome, awesome, awesome. It allowed me to correct spellings and flesh out things I wanted narrated. This also allowed me to type in a few sentences to summarize the first 15 minutes of our game that I forgot to record!"
          imgSrc={likely_mimics}
          name="Scott M."
          association="Likely Mimics"
          link="https://www.youtube.com/@LikelyMimics"
        />

        <Testimonial
          text="If you've ever turned recaps over to your players before and felt like nobody was listening for the entire session, you can count on Scrybe to not only listen to the entire recording but to pick out the most important parts that need to be reminded to your players to get them going next week."
          imgSrc={dm_timothy}
          name="Timothy Austen"
          association="DM Timothy"
          link="https://www.youtube.com/@DM-Timothy"
        />

        <Testimonial
          text="This innovation not only enhances the continuity and depth of campaigns but also allows GMs to devote more energy to creativity and player engagement."
          imgSrc={the_enchanted_scribe}
          name="Danny McKeever"
          association="The Enchanted Scribe"
          link="https://www.the-enchanted-scribe.com/"
        />

        <Testimonial
          text="I was completely blown away by how accurately it recognizes the story centric details amongst the many hours of content and uses that as the basis of the summary. This program will definitely be a staple in my sessions from now on."
          name="Christopher R."
          association="Scrybe User"
          initials="CR"
        />
      </Splide>

      {/* Custom navigation buttons */}
      <div className="w-full flex justify-center gap-4">
        <button
          onClick={handlePrevClick}
          className="py-2 px-3 flex items-center justify-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 min-w-[85px]"
        >
          {' '}
          <svg
            className="rotate-90 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
          Prev
        </button>

        <button
          onClick={handleNextClick}
          className="py-2 px-3 flex items-center justify-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 min-w-[85px]"
        >
          Next{' '}
          <svg
            className="-rotate-90 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
