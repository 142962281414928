import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';
import { ReactComponent as SpilledInkSignatureIcon } from '../assets/Spilled_Ink_Signature.svg';

// import react link
import { Link } from 'react-router-dom';

import me from '../assets/me.jpg';

function DevLogPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Dev Log
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[16em] mx-auto">
          Last updated: December 10th, 2024
        </p>
      </div>

      {/* v1.3 */}
      <div>
        <h2 className="text-lg font-semibold dark:text-white flex items-center">
          <span className="font-bold">Version</span>
          <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-orange-100 text-orange-800 inline-flex items-center justify-center">
            v1.3
          </span>
        </h2>
        <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
          December 10, 2024
        </p>
        <ul className="list-disc pl-8 mb-5">
          {/* <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">📁 Added campaign organization</span>{' '}
            - Group your recaps together
            <ul className="list-disc pl-8 mt-2">
              <li className="dark:text-gray-400">Spelling dictionary</li>
              <li className="dark:text-gray-400">Pronunciation dictionary</li>
            </ul>
          </li> */}
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🛠️ Major bug fixes</span>
            <ul className="list-disc pl-8 mt-2">
              <li className="dark:text-gray-400">
                Fixed issue that caused some recaps to get stuck during
                transcription step
              </li>
              <li className="dark:text-gray-400">
                New Recap form now retains selections on accidental close and
                properly resets after submission
              </li>
            </ul>
          </li>
          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">
              ⬆️ Increased upload limit to 800 MB
            </span>
          </li>
          {/* <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🛒 Added a shop</span> - Now offering
            bundles of narrators and background music
          </li> */}
          {/* <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
            Oxley, Rudra, Jora
          </li> */}

          <li className="mb-2 dark:text-gray-400">
            <span className="font-medium">🎵 Added new background music:</span>{' '}
            Curse of the Manor, Ghosties and Ghoulies, Let the Mystery Unfold, Magic in the Air, Midnight Coven, The Abandoned Manor, The Crystal Cave, The Enchanted Kingdom, The Lights of the Village, The Lowlands, The Wishing Well, Ways of the Wizard, Witches Cauldron
          </li>
          <li className="mb-2 dark:text-gray-400">
            Made the navbar more functional (and prettier too!)
          </li>
          <li className="mb-2 dark:text-gray-400">
            Added a{' '}
            <Link to="/partners" className="underline hover:text-gray-600">
              Partners page
            </Link>
          </li>
        </ul>
      </div>

      <div>
        {/* v1.2 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-teal-100 text-teal-800 inline-flex items-center justify-center">
              v1.2
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            November 2, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                📝 Added option to select third person perspective
              </span>{' '}
              - Great for recaps directed to an audience. You can also
              optionally specify your adventuring party's name instead of
              defaulting to "the party".
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                ⬆️ Increased recap detail and length
              </span>
            </li>
            {/* decrease */}
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                💾 Massively optimized video file size
              </span>{' '}
              - Video recaps are now ~65% smaller, now consistently fitting into
              Discord's 10MB upload limit
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">⚙️ Increased TTS stability</span> -
              reduced fast talking and unusual narrator inflections
            </li>
            <li className="mb-2 dark:text-gray-400">
              Swapped to higher quality TTS model for some narrators
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v1.1 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-violet-100 text-violet-800 inline-flex items-center justify-center">
              v1.1
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            September 23, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🛠️ Massively improved recap summarization quality
              </span>{' '}
              (Upgraded from GPT-4o to o1-preview)
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
              Arthur, Gideon, Jack, Jessica, Klaus, Felix, and Reginald
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🔍 Added search bar, filters, and pagination
              </span>{' '}
              to the "My Recaps" page for better organization
            </li>
            <li className="mb-2 dark:text-gray-400">
              Added option to sign up with email and password instead of just
              Google
            </li>
            <li className="mb-2 dark:text-gray-400">
              Made narrator tags more descriptive
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v1.0 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-blue-100 text-blue-800 inline-flex items-center justify-center">
              v1.0
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            May 20, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                ✏️ You can now manually edit the text recaps
              </span>{' '}
              - Correct spelling or add custom text before proceeding to
              generate the audio and video recaps
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🔊 Added audio previews for narrators and background music
              </span>
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
              Alfie, Carter, Claria, Eleanor, Fizzlebert, George, Mira, Nasim,
              Ronan, Silas, and Von
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🎵 Added new background music:
              </span>{' '}
              Adventure, Basilica of the Heavens, Beautiful Village, Cursed
              Island, Dwarven King's Tomb, Geof the Blacksmith, Link Street,
              Magical Forest, Old Creek Grove, Protecting Neverwinter, Pulse of
              the Unknown, Quaint Quest, Queen of the Dead, Spirits Refuge, The
              City of Akaton, The Dragon Hoard, The Herbalist, The Long Path,
              The White City, Tiny Kingdom, Uncertain Tidings, Whispering Door,
              Winterlight, Without God, and Wode
            </li>
            <li className="mb-2 dark:text-gray-400">
              Added an{' '}
              <Link to="/faq" className="underline hover:text-gray-600">
                FAQ page
              </Link>
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v0.1 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Alpha Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-gray-100 text-gray-800 inline-flex items-center justify-center">
              v0.1
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-sm">
            February 6, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              Scrybe officially launched to the public 😎
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* About me */}
        <div className="flex flex-col items-center rounded-xl">
          <h2 className="text-lg font-semibold dark:text-white">
            <span className="font-bold font-inknut text-2xl">About Me</span>
          </h2>
          <div className="flex flex-col lg:flex-row items-center lg:items-stretch mt-5 lg:mt-8 gap-10">
            <div className="w-full lg:w-5/12 flex justify-center mb-5 md:mb-0">
              <img
                src={me}
                alt="Me"
                className="object-cover rounded-lg drop-shadow-md w-72 lg:w-full"
              />
            </div>
            <div className="w-full lg:w-7/12">
              <h3 className="text-lg font-bold dark:text-gray-400 mt-3 md:mt-0">
                Mark
              </h3>
              <p className="text-sm uppercase text-gray-600">
                Founder & Developer
              </p>
              <p className="dark:text-gray-400 mt-5">
                Hey there! My name is Mark and I am the creator of Scrybe. I'm a
                recent software engineering grad who has always loved playing
                TTRPGs like D&D and Blades in the Dark.
              </p>
              <p className="dark:text-gray-400 mt-5">
                Scrybe started out as a small passion project of mine which I
                turned into the site you're on right now. It all began with me
                manually cobbling together recaps for my own group with various
                online tools and metaphorical duct tape.
              </p>

              <p className="dark:text-gray-400 mt-5">
                I am currently the sole developer and maintainer of Scrybe. If
                you are interested in potentially working with me on Scrybe or
                just want to chat, I'd love to hear from you at{' '}
                <a
                  href="mailto:mark@scrybequill.com"
                  className="text-gray-600 underline"
                >
                  mark@scrybequill.com
                </a>
              </p>

              <p className="dark:text-gray-400 mt-5">
                I hope you enjoy using Scrybe as much as I enjoyed creating it!
              </p>

              <div className="flex justify-end items-center mt-7 dark:text-gray-400">
                <p className="mr-1">- Mark</p>
                <SpilledInkSignatureIcon className="w-14 h-14 -mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default DevLogPage;
