import { Menu, Transition } from '@headlessui/react';

import { useParams, useNavigate } from 'react-router-dom';
import { auth, storage, db } from '../firebaseConfig';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  updateDoc,
  collection,
  addDoc,
  Timestamp,
  doc,
  getDoc,
} from 'firebase/firestore';
import Select, { components } from 'react-select';
import { FaPlay, FaStop, FaUpload } from 'react-icons/fa';
import { MdFileUpload } from 'react-icons/md';
import { FiUpload } from 'react-icons/fi';

import { modifyTokenBalance } from '../utils/FirebaseFunctionsHelper';

import UploadField from './UploadField';

// Narrator Dictionary
const narratorDict = {
  Arthur: '8ZBQD0m1R6EIchgSltwB',
  Josha: 'nyeXGhluSezAnVjsQsbQ',
  Ixel: 'Z77SbP4Tp0Wb71ywQ35v',
  Reginald: 'Hh3w0sOWNiqfS6SGhazH',
  Gideon: 'l7c1szzbbUm7hQxxE1Aw',
  Victoria: 'fnHrJf6lxn8qpNfdSuAu',
  Joanne: 'j1xLWTLKxEl0e6WENGs0',
  Cecile: 'bvz5eNAMkfKPt7uTLym7',
  Jami: 'lUE3vcpcTVGfJ7KVgjbA',
  Oswald: 'UMkQssU5lwO9uLwTkGIh',
  Jack: 'PPzYpIqttlTYA83688JI',
  James: 'zpnRoleXRhWcv8KmQc0N',
  Jessica: 'flHkNRp1BlvT73UL6gyz',
  Felix: 'i8yKObbsUKqDEXllicpF',
  Robert: '2HEvybW5JPcDQ4g23KG5',
  Asha: 'o3DDJnC41aNSrsgfkrXJ',
  Lee: 'abRFZIdN4pvo8ZPmGxHP',
  Andromeda: 'Xw54iFVGvOkBBF7zOf0Z',
  Klaus: 'nzeAacJi50IvxcyDnMXa',
  Carter: 'qNkzaJoHLLdpvgh5tISm',
  Ronan: 'V4aTMuwwYUtBD7ZqVvZs',
  Von: 'Mg1264PmwVoIedxsF9nu',
  Silas: 'KTPVrSVAEUSJRClDzBw7',
  Eleanor: 'VzyQ6qo0GJY1pcTvoV5g',
  Fizzlebert: 'f2yUVfK5jdm78zlpcZ8C',
  Claria: '1vHrrFQuLuyqEl17e9gl',
  Nasim: 'tlETan7Okc4pzjD0z62P',
  Alfie: 'v9I7auPeR1xGKYRPwQGG',
  Mira: 'sVSfqK2DvxZjFxL8fd8g',
  George: 'geeIXR6sVlEJDekIvnOL',
  // Oxley: 'iiidtqDt9FBdT1vfBluA',
  // Rudra: 'tTZ0TVc9Q1bbWngiduLK',
  // Jora: 'OlBp4oyr3FBAGEAtJOnU'
};

const UploadSession = ({ currUser }) => {
  const [sessions, setSessions] = useState([]);
  const [sessionName, setSessionName] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCustomMusicFile, setSelectedCustomMusicFile] = useState(null);

  const [progress, setProgress] = useState(0);
  const [customMusicUploadProgress, setCustomMusicUploadProgress] = useState(0);

  const [narrator, setNarrator] = useState('George');
  const [narratorModel, setNarratorModel] = useState('eleven_multilingual_v2');

  const [backgroundMusicName, setBackgroundMusicName] = useState(
    'Expedition Planning'
  );
  const [backgroundMusic, setBackgroundMusic] = useState('expedition_planning');
  const [showCustomMusicInput, setShowCustomMusicInput] = useState(false);

  const [tense, setTense] = useState('second');
  const tenseOptions = [
    { value: 'second', label: '"Last session, you..."' },
    { value: 'third', label: '"Last session, the party..."' },
  ];

  const [partyName, setPartyName] = useState(null);

  const [currentPlaying, setCurrentPlaying] = useState({
    audio: null,
    id: null,
  });

  const [uploading, setUploading] = useState(false);

  const closeMenuRef = useRef(null);

  const [buttonText, setButtonText] = useState('Submit');

  const [isCheckedManualEdit, setIsCheckedManualEdit] = useState(false);

  function clearForm() {
    setSessionName('');
    setSelectedFile(null);
    setSelectedCustomMusicFile(null);
    setProgress(0);
    setCustomMusicUploadProgress(0);
    setNarrator('George');
    setNarratorModel('eleven_multilingual_v2');
    setBackgroundMusicName('Expedition Planning');
    setBackgroundMusic('expedition_planning');
    setShowCustomMusicInput(false);
    setTense('second');
    setPartyName(null);
    setIsCheckedManualEdit(false);
  }

  let navigate = useNavigate();

  function getBackgroundMusicPath(backgroundMusicName) {
    if (backgroundMusicName === 'custom') {
      return `users/${auth.currentUser.uid}/custom_music/${selectedCustomMusicFile.name}`;
    } else {
      return `background_music/background_music_${backgroundMusicName.toLowerCase().replace(' ', '_')}.mp3`;
    }
  }

  function handleCustomMusicUpload(docRef) {
    const storage_path = `users/${auth.currentUser.uid}/custom_music/${selectedCustomMusicFile.name}`;
    const storageRef = ref(storage, storage_path);
    const uploadTask = uploadBytesResumable(
      storageRef,
      selectedCustomMusicFile
    );

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setCustomMusicUploadProgress(progress);
      },
      (error) => {
        console.error(error);
        setUploading(false);
        setButtonText('Submit');
      },
      async () => {
        setUploading(false);
        setButtonText('Submit');

        // Update session document with the file URL
        await updateDoc(docRef, { customMusicStoragePath: storage_path });
      }
    );
  }

  // Function to check if user has at least one token
  const hasAtLeastOneToken = async (userId) => {
    try {
      // Create a reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', userId);

      // Fetch the user document
      const userDocSnapshot = await getDoc(userDocRef);

      // Check if the document exists
      if (userDocSnapshot.exists()) {
        // Get the user's token balance
        const tokenBalance = userDocSnapshot.get('tokenBalance');

        // Return true if the tokenBalance is at least 1
        return tokenBalance >= 1;
      } else {
        console.log('User document does not exist');
        return false;
      }
    } catch (error) {
      console.error('Error fetching user token balance: ', error);
      return false;
    }
  };

  function sanitizeFileName(fileName) {
    // Remove any null bytes
    fileName = fileName.replace(/\0/g, '');
    // Remove directory separators
    fileName = fileName.replace(/[\/\\]/g, '_');
    // Remove any characters except alphanumerics, dot, hyphen, underscore
    fileName = fileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
    // Optionally, limit the length of the file name
    if (fileName.length > 255) {
      fileName = fileName.substring(0, 255);
    }
    return fileName;
  }

  const handleCreateSession = async (e) => {
    e.preventDefault();

    if (!currUser) {
      alert('Please sign up or log in to generate recaps.');
      return;
    }

    // Prevent duplicate submissions
    if (uploading) return;

    // Make sure form is filled out
    if (!sessionName) {
      alert('Please enter a recap name');
      return;
    }
    if (!selectedFile) {
      alert('Please select a session recording to upload');
      return;
    }

    try {
      setUploading(true);

      // Check if user has at least 1 token
      const hasToken = await hasAtLeastOneToken(auth.currentUser.uid);
      if (!hasToken) {
        alert(
          'You have no ink left. Please top up your ink supply to continue.'
        );
        setUploading(false);
        setButtonText('Submit');
        return;
      }

      setButtonText('Uploading');

      // Add to Firestore
      const newSession = {
        name: sessionName,
        sessionRecordingStoragePath: null,
        transcription: null,
        timeCreated: Timestamp.now(),

        recaps: {
          recap1: {
            summary: null,
            narrationStoragePath: null,
            narrator: {
              name: narrator,
              id: narratorDict[narrator],
              model: narratorModel,
            },
            backgroundMusicPath: getBackgroundMusicPath(backgroundMusic),
            backgroundMusicName: backgroundMusicName,
            narratedVideoStoragePath: null,
            manualEdit: isCheckedManualEdit,
            tense: tense, // Add this line
            partyName: partyName,
          },
        },
      };

      const docRef = await addDoc(
        collection(
          db,
          `users/${auth.currentUser.uid}/campaigns/recaps/sessions`
        ),
        newSession
      );

      // If custom music file is selected, upload it
      if (selectedCustomMusicFile) {
        handleCustomMusicUpload(docRef);
      }

      // Handle Upload
      const sanitizedFileName = sanitizeFileName(selectedFile.name);
      const storage_path = `users/${auth.currentUser.uid}/recordings/${sanitizedFileName}`;

      const storageRef = ref(storage, storage_path);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error(error);
          setUploading(false);
          setButtonText('Submit');
        },
        async () => {
          // Deduct 1 token from the user's balance
          setButtonText('Dipping Quill');
          await modifyTokenBalance(auth.currentUser.uid, -1);
          setUploading(false);
          setButtonText('Submit');

          // navigate to the new session page
          if (closeMenuRef.current) {
            closeMenuRef.current();
          }

          navigate('/campaigns/recaps');

          // Update session document with the file URL
          await updateDoc(docRef, {
            sessionRecordingStoragePath: storage_path,
          });

          // Clear the form
          clearForm();

          // Update the local state with new session info
          newSession.sessionRecordingStoragePath = storage_path;
          newSession.id = docRef.id;
          setSessions([newSession, ...sessions]);
          console.log('Session ID: ', docRef.id);

          const endpoint = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL_BASE}/create_session`;
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userID: auth.currentUser.uid,
              campaignID: 'recaps',
              sessionID: docRef.id,
              recordingFileName: sanitizedFileName,
              narrator: narratorDict[narrator],
              narratorModel: narratorModel,
              backgroundMusic: getBackgroundMusicPath(backgroundMusic),
              manualEdit: isCheckedManualEdit,
              tense: tense,
              partyName: partyName,
            }),
          });

          const result = await response.json();
          if (response.ok) {
            console.log('Session created with ID:', result.sessionId);
          } else {
            console.error('Error in creating session: ', result.message);
            // Update UI based on error
          }
        }
      );
    } catch (error) {
      setUploading(false);
      setButtonText('Submit');
      console.error('Error in creating session: ', error);
    }
  };

  const suppressSpaceKey = (e) => {
    if (e.key === ' ') {
      e.stopPropagation();
    }
  };

  const handleMusicSelection = (selectedMusic) => {
    setBackgroundMusic(selectedMusic);

    // Check if the selected option is 'Custom'
    if (selectedMusic === 'custom') {
      setShowCustomMusicInput(true); // Show the custom input field
    } else {
      setShowCustomMusicInput(false); // Hide the custom input field
    }
  };

  const playSong = async (songPath, id) => {
    if (currentPlaying.audio && currentPlaying.id !== id) {
      currentPlaying.audio.pause();
      currentPlaying.audio.currentTime = 0;
    }

    try {
      const songRef = ref(storage, songPath);
      const url = await getDownloadURL(songRef);
      // console.log("URL: ", url);
      const newAudio = new Audio(url);
      newAudio.volume = 0.5;

      setCurrentPlaying({ audio: newAudio, id: id });

      // Await the play promise to handle it properly
      // console.log("Trying to play audio")
      newAudio.addEventListener('loadeddata', () => {
        newAudio.play();
      });
      // console.log("Audio played")
      // Cleanup when audio finishes
      newAudio.onended = () => {
        setCurrentPlaying({ audio: null, id: null });
      };
    } catch (error) {
      console.error('Error playing audio:', error);
      // Handle the error, possibly by retrying the play command or logging the error
    }
  };

  const stopSong = () => {
    if (currentPlaying.audio) {
      currentPlaying.audio.pause();
      currentPlaying.audio.currentTime = 0;
      setCurrentPlaying({ audio: null, id: null });
    }
  };

  const narratorOptions = [
    {
      value: 'Andromeda',
      label: 'Andromeda',
      songPath: 'narrator_samples/narrator_sample_andromeda.mp3',
      voice_type: 'feminine',
      tags: ['Space Explorer'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Arthur',
      label: 'Arthur',
      songPath: 'narrator_samples/narrator_sample_arthur.mp3',
      voice_type: 'masculine',
      tags: ['Wizard', 'Wise'],
      model: 'eleven_turbo_v2_5',
    },
    {
      value: 'Asha',
      label: 'Asha',
      songPath: 'narrator_samples/narrator_sample_asha.mp3',
      voice_type: 'feminine',
      tags: ['Sage', 'Eloquent'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Cecile',
      label: 'Cecile',
      songPath: 'narrator_samples/narrator_sample_cecile.mp3',
      voice_type: 'feminine',
      tags: ['Grandma', 'Mature'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Gideon',
      label: 'Gideon',
      songPath: 'narrator_samples/narrator_sample_gideon.mp3',
      voice_type: 'masculine',
      tags: ['Duke', 'Deep'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'Ixel',
      label: 'Ixel',
      songPath: 'narrator_samples/narrator_sample_ixel.mp3',
      voice_type: 'masculine',
      tags: ['Philosopher', 'Keen'],
      model: 'eleven_monolingual_v1',
    },
    {
      value: 'Jack',
      label: 'Jack',
      songPath: 'narrator_samples/narrator_sample_jack.mp3',
      voice_type: 'masculine',
      tags: ['Pirate', 'Dramatic'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'James',
      label: 'James',
      songPath: 'narrator_samples/narrator_sample_james.mp3',
      voice_type: 'masculine',
      tags: ['Merchant', 'Friendly'],
      model: 'eleven_multilingual_v1',
    },
    {
      value: 'Jessica',
      label: 'Jessica',
      songPath: 'narrator_samples/narrator_sample_jessica.mp3',
      voice_type: 'feminine',
      tags: ['Villain', 'Sultry'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'Joanne',
      label: 'Joanne',
      songPath: 'narrator_samples/narrator_sample_joanne.mp3',
      voice_type: 'feminine',
      tags: ['Young', 'Soft'],
      model: 'eleven_monolingual_v1',
    },
    {
      value: 'Josha',
      label: 'Josha',
      songPath: 'narrator_samples/narrator_sample_josha.mp3',
      voice_type: 'masculine',
      tags: ['Lorekeeper', 'Deep'],
      model: 'eleven_monolingual_v1',
    },
    {
      value: 'Klaus',
      label: 'Klaus',
      songPath: 'narrator_samples/narrator_sample_klaus.mp3',
      voice_type: 'masculine',
      tags: ['Professor', 'German'],
      model: 'eleven_turbo_v2_5',
    },
    {
      value: 'Felix',
      label: 'Felix',
      songPath: 'narrator_samples/narrator_sample_felix.mp3',
      voice_type: 'masculine',
      tags: ['Court Mage', , 'Polish'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'Oswald',
      label: 'Oswald',
      songPath: 'narrator_samples/narrator_sample_oswald.mp3',
      voice_type: 'masculine',
      tags: ['Historian', 'Intelligent'],
      model: 'eleven_monolingual_v1',
    },
    {
      value: 'Robert',
      label: 'Robert',
      songPath: 'narrator_samples/narrator_sample_robert.mp3',
      voice_type: 'masculine',
      tags: ['Old-Timey', 'Radio'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Victoria',
      label: 'Victoria',
      songPath: 'narrator_samples/narrator_sample_victoria.mp3',
      voice_type: 'feminine',
      tags: ['Aristocrat', 'Classy'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Carter',
      label: 'Carter',
      songPath: 'narrator_samples/narrator_sample_carter.mp3',
      voice_type: 'masculine',
      tags: ['Commander', 'Deep'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Ronan',
      label: 'Ronan',
      songPath: 'narrator_samples/narrator_sample_ronan.mp3',
      voice_type: 'masculine',
      tags: ['Gruff', 'Scottish'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Von',
      label: 'Von',
      songPath: 'narrator_samples/narrator_sample_von.mp3',
      voice_type: 'masculine',
      tags: ['Mad Scientist', 'Eccentric'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Silas',
      label: 'Silas',
      songPath: 'narrator_samples/narrator_sample_silas.mp3',
      voice_type: 'masculine',
      tags: ['Cowboy', 'Southern'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Eleanor',
      label: 'Eleanor',
      songPath: 'narrator_samples/narrator_sample_eleanor.mp3',
      voice_type: 'feminine',
      tags: ['Serious', 'Mature'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Fizzlebert',
      label: 'Fizzlebert',
      songPath: 'narrator_samples/narrator_sample_fizzlebert.mp3',
      voice_type: 'masculine',
      tags: ['Trickster', 'Gnome'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'Claria',
      label: 'Claria',
      songPath: 'narrator_samples/narrator_sample_claria.mp3',
      voice_type: 'feminine',
      tags: ['Guildmaster', 'Formal'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Nasim',
      label: 'Nasim',
      songPath: 'narrator_samples/narrator_sample_nasim.mp3',
      voice_type: 'masculine',
      tags: ['Oracle', 'Arabic'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'Alfie',
      label: 'Alfie',
      songPath: 'narrator_samples/narrator_sample_alfie.mp3',
      voice_type: 'masculine',
      tags: ['Shepherd', 'Calm'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'Mira',
      label: 'Mira',
      songPath: 'narrator_samples/narrator_sample_mira.mp3',
      voice_type: 'feminine',
      tags: ['Mercenary', 'Assertive'],
      model: 'eleven_turbo_v2',
    },
    {
      value: 'George',
      label: 'George',
      songPath: 'narrator_samples/narrator_sample_george.mp3',
      voice_type: 'masculine',
      tags: ['Storyteller', 'Calm'],
      model: 'eleven_multilingual_v2',
    },
    {
      value: 'Reginald',
      label: 'Reginald',
      songPath: 'narrator_samples/narrator_sample_reginald.mp3',
      voice_type: 'masculine',
      tags: ['Royal Advisor', 'Bold'],
      model: 'eleven_turbo_v2',
    },
    // {
    //   value: 'Oxley',
    //   label: 'Oxley',
    //   songPath: 'narrator_samples/narrator_sample_oxley.mp3',
    //   voice_type: 'masculine',
    //   tags: ['Bard', 'Melodic'],
    //   model: 'eleven_turbo_v2',

    // },
    // {
    //   value: 'Rudra',
    //   label: 'Rudra',
    //   songPath: 'narrator_samples/narrator_sample_rudra.mp3',
    //   voice_type: 'masculine',
    //   tags: ['Sorcerer', 'Cunning', 'Indian'],
    //   model: 'eleven_eleven_turbo_v2',
    // },
    // {
    //   value: 'Jora',
    //   label: 'Jora',
    //   songPath: 'narrator_samples/narrator_sample_jora.mp3',
    //   voice_type: 'masculine',
    //   tags: ['Cutpurse', 'Sly', 'Middle-Aged'],
    //   model: 'eleven_turbo_v2_5',
    // },
  ];

  // Sort narrator options alphabetically
  narratorOptions.sort((a, b) => a.label.localeCompare(b.label));

  const groupedOptionsVoices = [
    {
      label: 'Masculine',
      options: narratorOptions.filter(
        (option) => option.voice_type === 'masculine'
      ),
    },
    {
      label: 'Feminine',
      options: narratorOptions.filter(
        (option) => option.voice_type === 'feminine'
      ),
    },
  ];

  const groupedOptionsMusic = [
    // {
    //   options: [{ value: 'custom', label: 'Custom' }],
    // },
    {
      label: 'Calm',
      options: [
        {
          value: 'adventure',
          label: 'Adventure',
          songPath: 'background_music/background_music_adventure.mp3',
        },
        {
          value: 'basilica_of_the_heavens',
          label: 'Basilica of the Heavens',
          songPath:
            'background_music/background_music_basilica_of_the_heavens.mp3',
        },
        {
          value: 'beautiful_village',
          label: 'Beautiful Village',
          songPath: 'background_music/background_music_beautiful_village.mp3',
        },
        {
          value: 'expedition_planning',
          label: 'Expedition Planning',
          songPath: 'background_music/background_music_expedition_planning.mp3',
        },
        {
          value: 'geof_the_blacksmith',
          label: 'Geof the Blacksmith',
          songPath: 'background_music/background_music_geof_the_blacksmith.mp3',
        },
        {
          value: 'llanfair',
          label: 'Llanfair',
          songPath: 'background_music/background_music_llanfair.mp3',
        },
        {
          value: 'magical_forest',
          label: 'Magical Forest',
          songPath: 'background_music/background_music_magical_forest.mp3',
        },
        {
          value: 'minstrels_song',
          label: "Minstrel's Song",
          songPath: 'background_music/background_music_minstrels_song.mp3',
        },
        {
          value: 'mystical',
          label: 'Mystical',
          songPath: 'background_music/background_music_mystical.mp3',
        },
        {
          value: 'night_in_a_frozen_forest',
          label: 'Night in a Frozen Forest',
          songPath:
            'background_music/background_music_night_in_a_frozen_forest.mp3',
        },
        {
          value: 'old_creek_grove',
          label: 'Old Creek Grove',
          songPath: 'background_music/background_music_old_creek_grove.mp3',
        },
        {
          value: 'ring_of_iron',
          label: 'Ring of Iron',
          songPath: 'background_music/background_music_ring_of_iron.mp3',
        },
        {
          value: 'royal_market',
          label: 'Royal Market',
          songPath: 'background_music/background_music_royal_market.mp3',
        },
        {
          value: 'spirits_refuge',
          label: 'Spirits Refuge',
          songPath: 'background_music/background_music_spirits_refuge.mp3',
        },
        {
          value: 'the_herbalist',
          label: 'The Herbalist',
          songPath: 'background_music/background_music_the_herbalist.mp3',
        },
        {
          value: 'tiny_kingdom',
          label: 'Tiny Kingdom',
          songPath: 'background_music/background_music_tiny_kingdom.mp3',
        },
        {
          value: 'winterlight',
          label: 'Winterlight',
          songPath: 'background_music/background_music_winterlight.mp3',
        },
        {
          value: 'wode',
          label: 'Wode',
          songPath: 'background_music/background_music_wode.mp3',
        },
        {
          value: 'world_asleep',
          label: 'World Asleep',
          songPath: 'background_music/background_music_world_asleep.mp3',
        },
        {
          value: 'you_are_going_home',
          label: 'Going Home',
          songPath: 'background_music/background_music_you_are_going_home.mp3',
        },
        {
          value: 'the_enchanted_kingdom',
          label: 'The Enchanted Kingdom',
          songPath:
            'background_music/background_music_the_enchanted_kingdom.mp3',
          new: true,
        },
        {
          value: 'the_lights_of_the_village',
          label: 'The Lights of the Village',
          songPath:
            'background_music/background_music_the_lights_of_the_village.mp3',
          new: true,
        },
        {
          value: 'the_lowlands',
          label: 'The Lowlands',
          songPath: 'background_music/background_music_the_lowlands.mp3',
          new: true,
        },
        {
          value: 'the_wishing_well',
          label: 'The Wishing Well',
          songPath: 'background_music/background_music_the_wishing_well.mp3',
          new: true,
        },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: 'Epic',
      options: [
        {
          value: 'dwarven_kings_tomb',
          label: "Dwarven King's Tomb",
          songPath: 'background_music/background_music_dwarven_kings_tomb.mp3',
        },
        {
          value: 'pulse_of_the_unknown',
          label: 'Pulse of the Unknown',
          songPath:
            'background_music/background_music_pulse_of_the_unknown.mp3',
        },
        {
          value: 'protecting_neverwinter',
          label: 'Protecting Neverwinter',
          songPath:
            'background_music/background_music_protecting_neverwinter.mp3',
        },
        {
          value: 'wonderlust',
          label: 'Wonderlust',
          songPath: 'background_music/background_music_wonderlust.mp3',
          new: true,
        },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: 'Mysterious',
      options: [
        {
          value: 'cursed_island',
          label: 'Cursed Island',
          songPath: 'background_music/background_music_cursed_island.mp3',
        },
        {
          value: 'jewel_of_nekhen',
          label: 'Jewel of Nekhen',
          songPath: 'background_music/background_music_jewel_of_nekhen.mp3',
        },
        {
          value: 'link_street',
          label: 'Link Street',
          songPath: 'background_music/background_music_link_street.mp3',
        },
        {
          value: 'queen_of_the_dead',
          label: 'Queen of the Dead',
          songPath: 'background_music/background_music_queen_of_the_dead.mp3',
        },
        {
          value: 'the_dragon_hoard',
          label: 'The Dragon Hoard',
          songPath: 'background_music/background_music_the_dragon_hoard.mp3',
        },
        {
          value: 'the_legend_of_narmer',
          label: 'The Legend of Narmer',
          songPath:
            'background_music/background_music_the_legend_of_narmer.mp3',
        },
        {
          value: 'victorian',
          label: 'Victorian Mystery',
          songPath: 'background_music/background_music_victorian.mp3',
        },
        {
          value: 'curse_of_the_manor',
          label: 'Curse of the Manor',
          songPath: 'background_music/background_music_curse_of_the_manor.mp3',
          new: true,
        },
        {
          value: 'ghosties_and_ghoulies',
          label: 'Ghosties and Ghoulies',
          songPath:
            'background_music/background_music_ghosties_and_ghoulies.mp3',
          new: true,
        },
        {
          value: 'let_the_mystery_unfold',
          label: 'Let the Mystery Unfold',
          songPath:
            'background_music/background_music_let_the_mystery_unfold.mp3',
          new: true,
        },
        {
          value: 'magic_in_the_air',
          label: 'Magic in the Air',
          songPath: 'background_music/background_music_magic_in_the_air.mp3',
          new: true,
        },
        {
          value: 'the_crystal_cave',
          label: 'The Crystal Cave',
          songPath: 'background_music/background_music_the_crystal_cave.mp3',
          new: true,
        },
        {
          value: 'ways_of_the_wizard',
          label: 'Ways of the Wizard',
          songPath: 'background_music/background_music_ways_of_the_wizard.mp3',
          new: true,
        },
        {
          value: 'witches_cauldron',
          label: 'Witches Cauldron',
          songPath: 'background_music/background_music_witches_cauldron.mp3',
          new: true,
        },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: 'Tense',
      options: [
        {
          value: 'coven',
          label: 'Coven',
          songPath: 'background_music/background_music_coven.mp3',
        },
        {
          value: 'the_long_path',
          label: 'The Long Path',
          songPath: 'background_music/background_music_the_long_path.mp3',
        },
        {
          value: 'uncertain_tidings',
          label: 'Uncertain Tidings',
          songPath: 'background_music/background_music_uncertain_tidings.mp3',
        },
        {
          value: 'whispering_door',
          label: 'Whispering Door',
          songPath: 'background_music/background_music_whispering_door.mp3',
        },
        {
          value: 'without_god',
          label: 'Without God',
          songPath: 'background_music/background_music_without_god.mp3',
        },
        {
          value: 'midnight_coven',
          label: 'Midnight Coven',
          songPath: 'background_music/background_music_midnight_coven.mp3',
        },
        {
          value: 'the_abandoned_manor',
          label: 'The Abandoned Manor',
          songPath: 'background_music/background_music_the_abandoned_manor.mp3',
        },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    },
  ];

  const CustomOption = ({ data, isPlaying, ...props }) => {
    const [loading, setLoading] = useState(false);

    const togglePlay = async (e) => {
      e.stopPropagation();

      if (isPlaying) {
        // set isPlaying to false
        isPlaying = false;
        stopSong();
      } else {
        setLoading(true);
        await playSong(data.songPath, data.value);
        setLoading(false);
      }
    };

    return (
      <components.Option {...props}>
        <div className="flex items-center gap-3 ">
          {/* Play/Stop button */}
          {data.songPath && (
            <button
              type="button"
              className="background-none border-none"
              onClick={togglePlay}
            >
              {loading ? (
                <div
                  className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent rounded-full"
                  role="status"
                  aria-label="loading"
                />
              ) : isPlaying ? (
                <FaStop className="w-4 h-4 hover:opacity-50" />
              ) : (
                <FaPlay className="w-4 h-4 hover:opacity-50" />
              )}
            </button>
          )}

          {data.value === 'custom' && <FiUpload className="w-4 h-4" />}

          {/* Name */}
          {data.label}

          {/* Tags */}
          <div className="flex overflow-auto gap-2">
            {data.new && ( // Conditionally render "New" tag
              <span
                className={`px-2 py-1 text-xxs uppercase text-black font-semibold bg-gray-100 border border-gray-200 rounded-full whitespace-nowrap ${props.isSelected ? 'bg-neutral-700 text-white border-none' : ''}`}
              >
                New
              </span>
            )}
            {data.tags &&
              data.tags.map((tag, index) => (
                <span
                  key={index}
                  // className="inline-block px-2 py-1 text-xs font-semibold border border-gray-500 whitespace-nowrap rounded-full"
                  className={` px-2 py-1 text-xxs uppercase text-black font-semibold border border-gray-200 rounded-full whitespace-nowrap ${props.isSelected ? ' text-white border-neutral-600' : ''}`}
                >
                  {tag}
                </span>
              ))}
          </div>
        </div>
      </components.Option>
    );
  };

  const stopAudioOnClose = () => {
    if (currentPlaying.audio) {
      stopSong(); // This is the function you already have
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ close }) => (
        <>
          {(closeMenuRef.current = close)}
          <div className="relative z-10 rounded-full lg:border-12 bg-white border-white lg:shadow-md">
            <Menu.Button className="font-inknut font-bold flex justify-center py-3 px-4 sm:py-3 sm:px-4 w-full lg:w-44 max-w-full min-w-0 flex-grow inline-flex items-center whitespace-nowrap gap-x-2 text-sm font-bold rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
              + New Recap
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            afterLeave={stopAudioOnClose} // Stop audio when menu closes
          >
            <Menu.Items className="absolute left-1/2 top-1/2 mt-8 lg:mt-0 -translate-x-1/2 w-[85vw] max-w-[40rem] border bg-white shadow-md rounded-xl">
              {/* <Menu.Items className="absolute left-1/2 top-1/2 -translate-x-1/2 w-[85vw] max-w-[40rem] border bg-white shadow-md rounded-xl"> */}
              <form onSubmit={handleCreateSession}>
                <div className="py-4 sm:py-7 ">
                  <div className="px-4 sm:px-7 flex flex-col gap-4 max-h-[calc(100dvh-216px)] overflow-y-auto">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="af-submit-app-recap-name"
                        className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200"
                      >
                        Recap name
                      </label>

                      <input
                        id="af-submit-app-recap-name"
                        type="text"
                        placeholder="Enter recap name"
                        value={sessionName}
                        onChange={(e) => setSessionName(e.target.value)}
                        onKeyDown={suppressSpaceKey}
                        className="border py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                        Narrator
                      </div>
                      <Select
                        // Default value is narratorOptions where value is 'George'
                        value={narratorOptions.find(
                          (option) => option.value === narrator
                        )}
                        options={groupedOptionsVoices}
                        // formatGroupLabel={formatGroupLabel}
                        components={{
                          Option: (props) => (
                            <CustomOption
                              {...props}
                              isPlaying={currentPlaying.id === props.data.value}
                            />
                          ),
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: 'rgb(229, 231, 235)',
                            borderRadius: '8px',
                          }),
                          option: (styles) => ({
                            ...styles,
                            cursor: 'pointer',
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: '256px', // Set your desired max height here
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgb(244, 245, 247)',
                            primary50: 'rgb(229, 231, 235)',
                            primary: 'black',
                          },
                        })}
                        onChange={(selected) => {
                          stopSong();
                          setNarrator(selected.label);
                          setNarratorModel(selected.model);
                        }}
                        onMenuClose={stopSong}
                        isSearchable={false}
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                        Background music
                      </div>
                      <Select
                        value={(() => {
                          // Flatten the grouped options to search across all options
                          const allOptions = groupedOptionsMusic.flatMap(
                            (group) => group.options
                          );
                          return allOptions.find(
                            (option) => option.value === backgroundMusic
                          );
                        })()}
                        options={groupedOptionsMusic}
                        // formatGroupLabel={formatGroupLabel}
                        components={{
                          Option: (props) => (
                            <CustomOption
                              {...props}
                              isPlaying={currentPlaying.id === props.data.value}
                            />
                          ),
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: 'rgb(229, 231, 235)',
                            borderRadius: '8px',
                          }),
                          option: (base, { isSelected }) => ({
                            ...base,
                            cursor: 'pointer',
                            color: isSelected ? 'white' : 'black', // Adjust text color based on selection
                          }),

                          menuList: (base) => ({
                            ...base,
                            maxHeight: '190px', // Set your desired max height here
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgb(229, 231, 235)',
                            primary50: 'rgb(229, 231, 235)',
                            primary: 'black',
                          },
                        })}
                        onChange={(selected) => {
                          stopSong();
                          setBackgroundMusicName(selected.label);
                          handleMusicSelection(selected.value);
                        }}
                        onMenuClose={stopSong}
                        isSearchable={false}
                      />
                    </div>

                    {showCustomMusicInput && (
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="af-custom-music"
                          className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200"
                        >
                          Upload Custom Background Music
                        </label>

                        <UploadField
                          supportedFileTypes={['mp3']}
                          maxFileSize={50}
                          onFileSelect={(selectedFile) => {
                            setSelectedCustomMusicFile(selectedFile);
                          }}
                        />

                        <div className="flex w-full mb-1 h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                          <div
                            className="flex flex-col justify-center overflow-hidden bg-gray-500 transition-width duration-300 ease-in-out"
                            role="progressbar"
                            style={{ width: `${customMusicUploadProgress}%` }}
                            aria-valuenow={customMusicUploadProgress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="af-submit-app-upload-session"
                        className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200"
                      >
                        Session recording
                      </label>

                      <UploadField
                        supportedFileTypes={['mp3', 'wav', 'mpeg', 'm4a']}
                        maxFileSize={800}
                        onFileSelect={(file) => setSelectedFile(file)}
                        value={selectedFile} // Tie the selected file state here
                      />

                      <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                        <div
                          className="flex flex-col justify-center overflow-hidden bg-gray-500 transition-width duration-300 ease-in-out"
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>

                    <div className="flex flex-col justify-center">
                      <button
                        type="button"
                        class="hs-collapse-toggle flex mx-auto justify-center items-center text-sm font-medium text-gray-500 dark:text-gray-200"
                        id="hs-basic-collapse"
                        aria-expanded="false"
                        aria-controls="hs-basic-collapse-heading"
                        data-hs-collapse="#hs-basic-collapse-heading"
                      >
                        Show advanced options
                        <svg
                          class="hs-collapse-open:rotate-180 shrink-0 w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="m6 9 6 6 6-6"></path>
                        </svg>
                      </button>
                      <div
                        id="hs-basic-collapse-heading"
                        class="hs-collapse hidden w-full transition-[height] duration-300 flex flex-col gap-4"
                        aria-labelledby="hs-basic-collapse"
                      >
                        <div className="flex flex-col gap-1 pt-4">
                          <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                            Recap perspective
                          </div>
                          <Select
                            value={tenseOptions.find(
                              (option) => option.value === tense
                            )}
                            options={tenseOptions}
                            styles={{
                              control: (base) => ({
                                ...base,
                                borderColor: 'rgb(229, 231, 235)',
                                borderRadius: '8px',
                              }),
                              option: (styles) => ({
                                ...styles,
                                cursor: 'pointer',
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'rgb(229, 231, 235)',
                                primary50: 'rgb(229, 231, 235)',
                                primary: 'black',
                              },
                            })}
                            onChange={(selected) => {
                              setTense(selected.value);
                            }}
                            isSearchable={false}
                          />
                        </div>

                        {tense === 'third' && (
                          <div className="flex flex-col gap-1">
                            <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                              Party name (optional)
                            </div>

                            <input
                              type="text"
                              placeholder="Enter party name"
                              value={partyName || ''}
                              onChange={(e) => setPartyName(e.target.value)}
                              onKeyDown={suppressSpaceKey}
                              className="border py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        )}

                        <div className="flex flex-col gap-1 mb-4 xs:mb-4">
                          <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                            Manually edit
                          </div>

                          <div className="gap-2">
                            <label
                              htmlFor="hs-checkbox-in-form"
                              className="cursor-pointer flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                            >
                              <input
                                type="checkbox"
                                checked={isCheckedManualEdit}
                                onChange={(e) =>
                                  setIsCheckedManualEdit(e.target.checked)
                                }
                                className="shrink-0 mt-0.5 border-gray-200 rounded text-gray-800 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-500 dark:checked:border-gray-500 dark:focus:ring-offset-gray-800"
                                id="hs-checkbox-in-form"
                              />

                              <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                                Manually edit recap before generating
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 pt-4 sm:px-7 flex flex-col gap-2 xs:flex-row justify-center xs:justify-end gap-x-2">
                    {!uploading && (
                      <button
                        onClick={close}
                        type="button"
                        className="py-2 px-3 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        Cancel
                      </button>
                    )}

                    <button
                      id="session-submit-btn"
                      type="submit"
                      disabled={!currUser}
                      className=" py-2 px-3 inline-flex items-center justify-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      {/* If not uploading, render Submit else render spinner */}

                      {currUser ? buttonText : 'Sign in to generate'}
                      {uploading && currUser && (
                        <div
                          className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        ></div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default UploadSession;
