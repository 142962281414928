import React, { useState, useEffect } from 'react';

import {
  FaCreativeCommons,
  FaRegQuestionCircle,
  FaRegCopy,
  FaCheck,
  FaSearch,
} from 'react-icons/fa';
import { LuExternalLink } from 'react-icons/lu';

import { ReactComponent as SpilledInkIcon } from '../assets/Spilled_Ink.svg';

function CreditSection({
  songNameScrybe,
  songNameOriginal,
  composerName,
  sourceLink,
  licenseLink,
  licenseText = 'Licensed under Creative Commons BY Attribution 4.0 License',
}) {
  // State to track copy success, determines which icon to show
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    const textToCopy = `Music: ${songNameOriginal} by ${composerName} ${sourceLink}\n\n${licenseText}\n\n${licenseLink}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopySuccess(true); // Show success icon
      setTimeout(() => setCopySuccess(false), 500); // Revert to copy icon after half a second
    });
  };

  return (
    <div className="flex flex-col justify-between h-full rounded-xl p-4 md:p-6 bg-white border border-gray-200 dark:bg-slate-900 dark:border-gray-700">
      <div>
        <div className="flex items-center gap-x-4">
          <div className="grow">
            <h3 className="font-medium text-gray-800 dark:text-gray-200">
              {songNameScrybe}
            </h3>
            <p className="text-xs uppercase text-gray-500">
              <a
                href={sourceLink}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                {composerName}
              </a>
            </p>
          </div>
        </div>

        <p className="mt-3 text-gray-500 break-words">
          Music: {songNameOriginal} by {composerName}{' '}
          <a href={sourceLink} target="_blank" rel="noopener noreferrer">
            {sourceLink}
          </a>
        </p>

        <p className="mt-3 text-gray-500 break-words">{licenseText}</p>
        <p className="mt-3 text-gray-500 break-words">
          <a href={licenseLink} target="_blank" rel="noopener noreferrer">
            {licenseLink}
          </a>
        </p>
      </div>

      <div className="flex justify-between items-center mt-6 gap-x-3">
        <div
          onClick={handleCopy}
          className="inline-flex justify-center w-4 h-4 items-center text-gray-500 hover:opacity-50 dark:text-gray-400 dark:hover:bg-gray-700 cursor-pointer"
        >
          {copySuccess ? (
            <FaCheck className="w-3 h-3" />
          ) : (
            <svg
              className="flex-shrink-0 w-4 h-4 group-hover:rotate-6 transition"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
              <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
            </svg>
          )}
        </div>
        <div className="flex items-center gap-x-3">
          <a
            className="inline-flex justify-center items-center text-gray-500 hover:opacity-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            target="_blank"
            rel="noopener noreferrer"
            href={sourceLink}
          >
            <LuExternalLink className="w-4 h-4" />
          </a>

          {licenseLink ? (
            <a
              className="inline-flex justify-center items-center text-gray-500 hover:opacity-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              target="_blank"
              rel="noopener noreferrer"
              href={licenseLink}
            >
              {licenseLink.includes('creativecommons') ? (
                <FaCreativeCommons className="w-4 h-4" />
              ) : (
                <FaRegQuestionCircle className="w-4 h-4" />
              )}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const songData = [
  {
    songNameScrybe: 'Adventure',
    songNameOriginal: 'Adventure',
    composerName: 'Alexander Nakarada',
    sourceLink: 'https://creatorchords.com/music/adventure/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },

  {
    songNameScrybe: 'Beautiful Village',
    songNameOriginal: 'Beautiful Village',
    composerName: 'David Fesliyan',
    sourceLink:
      'https://www.fesliyanstudios.com/royalty-free-music/download/beautiful-village/210/',
    licenseLink: 'https://www.fesliyanstudios.com/policy/',
    licenseText: "Licensed under Fesliyan Studios' Custom License",
  },

  {
    songNameScrybe: 'Expedition Planning',
    songNameOriginal: 'Calm Theme',
    composerName: "Steven O'Brien",
    sourceLink: 'https://www.steven-obrien.net/#calm-theme/',
    licenseLink:
      'https://www.steven-obrien.net/licenses/steven_obrien_rf_collection_music_license_agreement.pdf',
    licenseText: "Licensed under Steven O'Brien's Music License Agreement",
  },

  {
    songNameScrybe: 'Celebration',
    songNameOriginal: 'Celebration',
    composerName: 'Alexander Nakarada',
    sourceLink: 'https://www.creatorchords.com/music/celebration/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },
  {
    songNameScrybe: 'Coven',
    songNameOriginal: 'Hour of the Witch',
    composerName: 'Scott Buckley',
    sourceLink: 'https://www.scottbuckley.com.au/library/hour-of-the-witch/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },
  {
    songNameScrybe: 'Distant Sun',
    songNameOriginal: 'The Distant Sun',
    composerName: 'Scott Buckley',
    sourceLink: 'https://www.scottbuckley.com.au/library/the-distant-sun/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },

  {
    songNameScrybe: 'First Light',
    songNameOriginal: 'Last and First Light',
    composerName: 'Scott Buckley',
    sourceLink: 'https://www.scottbuckley.com.au/library/last-and-first-light/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },

  {
    songNameScrybe: 'Harsh Judgement',
    songNameOriginal: 'Divine Ascension',
    composerName: 'David Fesliyan',
    sourceLink:
      'https://www.fesliyanstudios.com/royalty-free-music/download/divine-ascension/3243/',
    licenseLink: 'https://www.fesliyanstudios.com/policy/',
    licenseText: "Licensed under Fesliyan Studios' Custom License",
  },

  {
    songNameScrybe: 'Mystical',
    songNameOriginal: 'Mystical Theme',
    composerName: "Steven O'Brien",
    sourceLink: 'https://www.steven-obrien.net/#mystical-theme',
    licenseLink:
      'https://www.steven-obrien.net/licenses/steven_obrien_rf_collection_music_license_agreement.pdf',
    licenseText: "Licensed under Steven O'Brien's Music License Agreement",
  },

  {
    songNameScrybe: 'Quaint Quest',
    songNameOriginal: 'Adventure Beyond',
    composerName: 'Alexander Nakarada',
    sourceLink: 'https://www.creatorchords.com/music/adventure-beyond/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },

  {
    songNameScrybe: 'The King of Trees',
    songNameOriginal: 'Le Roi des Arbres',
    composerName: 'Gentle Puck',
    sourceLink: 'https://soundcloud.com/gentil_puck/winterlight/',
    licenseLink: 'https://creativecommons.org/licenses/by-sa/3.0/',
    licenseText:
      'Licensed under Creative Commons BY Attribution ShareAlike 3.0 License',
  },

  {
    songNameScrybe: 'The Long Path',
    songNameOriginal: 'Realm of Snow',
    composerName: 'Gentle Puck',
    sourceLink: 'https://soundcloud.com/gentil_puck/realm-of-snow/',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'The White City',
    songNameOriginal: 'The White City',
    composerName: 'Gentle Puck',
    sourceLink: 'https://soundcloud.com/gentil_puck/the-white-city/',
    licenseLink: 'https://creativecommons.org/licenses/by-sa/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Tiny Kingdom',
    songNameOriginal: 'Tiny Kingdom',
    composerName: 'David Fesliyan',
    sourceLink:
      'https://www.fesliyanstudios.com/royalty-free-music/download/tiny-kingdom/154/',
    licenseLink: 'https://www.fesliyanstudios.com/policy/',
    licenseText: "Licensed under Fesliyan Studios' Custom License",
  },

  {
    songNameScrybe: 'Uncertain Tidings',
    songNameOriginal: 'Tundra',
    composerName: 'Gentle Puck',
    sourceLink: 'https://soundcloud.com/gentil_puck/tundra/',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameOriginal: 'Winterlight',
    songNameScrybe: 'Winterlight',
    composerName: 'Gentle Puck',
    sourceLink: 'https://soundcloud.com/gentil_puck/winterlight/',
    licenseLink: 'https://creativecommons.org/licenses/by-sa/3.0/',
    licenseText:
      'Licensed under Creative Commons BY Attribution ShareAlike 3.0 License',
  },

  {
    songNameScrybe: 'Without God',
    songNameOriginal: 'Without God',
    composerName: 'David Fesliyan',
    sourceLink:
      'https://www.fesliyanstudios.com/royalty-free-music/download/without-god/343/',
    licenseLink: 'https://www.fesliyanstudios.com/policy/',
    licenseText: "Licensed under Fesliyan Studios' Custom License",
  },

  {
    songNameScrybe: "At Day's End",
    songNameOriginal: "At Day's End by Firefly Light",
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink:
      'https://soundcloud.com/user-734462061/at-days-end-by-firefly-light',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Azure Purple Gold',
    songNameOriginal: 'Azure Purple Gold',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/azure-purple-gold',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Casting Off',
    songNameOriginal: 'Casting Off',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/casting-off',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Cesare Rides Again',
    songNameOriginal: 'Cesare Rides Again',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/cesare-rides-again',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Dreams of an Alpine Spring',
    songNameOriginal: 'Dreams of an Alpine Spring',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink:
      'https://soundcloud.com/user-734462061/dreams-of-an-alpine-spring',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Jewel of Nekhen',
    songNameOriginal: 'Jewel of Nekhen',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/jewel-of-nekhen',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Llanfair',
    songNameOriginal: 'Llanfair',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/llanfair',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Night in a Frozen Forest',
    songNameOriginal: 'Night in a Frozen Forest',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink:
      'https://soundcloud.com/user-734462061/night-in-a-frozen-forest',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Ring of Iron',
    songNameOriginal: 'Ring of Iron',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/ring-of-iron',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'Royal Market',
    songNameOriginal: 'Royal Market',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/royal-market',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: "The Governor's Minuet",
    songNameOriginal: "The Governor's Minuet",
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/the-governors-minuet',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'The Legend of Narmer',
    songNameOriginal: 'The Legend of Narmer',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/', // No link available
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },
  {
    songNameScrybe: 'The Ruins of Atlantis',
    songNameOriginal: 'The Ruins of Atlantis',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/the-ruins-of-atlantis',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },
  {
    songNameScrybe: "The Wizard's Garden",
    songNameOriginal: "The Wizard's Garden",
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/the-wizards-garden',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },
  // {
  //     songNameScrybe: "Waltz of the Skeleton Keys",
  //     songNameOriginal: "Waltz of the Skeleton Keys",
  //     composerName: "Ehren K. Wade (WombatNoisesAudio)",
  //     sourceLink: "https://soundcloud.com/user-734462061/waltz-of-the-skeleton-keys",
  //     licenseLink: "https://creativecommons.org/licenses/by/3.0/",
  //     licenseText: "Licensed under Creative Commons BY Attribution 3.0 License",
  // },
  {
    songNameScrybe: 'Going Home',
    songNameOriginal: 'You Are Going Home',
    composerName: 'Ehren K. Wade (WombatNoisesAudio)',
    sourceLink: 'https://soundcloud.com/user-734462061/you-are-going-home',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },

  {
    songNameScrybe: 'World Asleep',
    songNameOriginal: 'World Asleep',
    composerName: 'Arthur Vyncke',
    sourceLink: 'https://soundcloud.com/arthurvost/world-asleep',
    licenseLink: 'https://creativecommons.org/licenses/by-sa/3.0/',
    licenseText:
      'Licensed under Creative Commons BY Attribution ShareAlike 3.0 License',
  },

  {
    songNameScrybe: "Minstrel's Song",
    songNameOriginal: "Minstrel's Song",
    composerName: 'Keys of Moon',
    sourceLink:
      'https://soundcloud.com/keysofmoon/minstrels-song-medieval-fantasy-music-free-download',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },

  // {
  //   songNameScrybe: 'Magic Tavern',
  //   songNameOriginal: 'Magic Tavern',
  //   composerName: 'Alexander Nakarada',
  //   sourceLink: 'https://creatorchords.com/music/magic-tavern/',
  //   licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
  //   licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  // },

  {
    songNameScrybe: 'Basilica of the Heavens',
    songNameOriginal: 'Basilica of the Heavens',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Blood Magic',
    songNameOriginal: 'Blood Magic',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Cursed Island',
    songNameOriginal: 'Cursed Island',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: "Dwarven King's Tomb",
    songNameOriginal: "Dwarven King's Tomb",
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Geof, the Blacksmith',
    songNameOriginal: 'Geof, the Blacksmith',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Link Street',
    songNameOriginal: 'Link Street',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Magical Forest',
    songNameOriginal: 'Magical Forest',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Old Creek Grove',
    songNameOriginal: 'Old Creek Grove',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Protecting Neverwinter',
    songNameOriginal: 'Protecting Neverwinter',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Pulse of the Unknown',
    songNameOriginal: 'Pulse of the Unknown',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Queen of the Dead',
    songNameOriginal: 'Queen of the Dead',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: "Spirit's Refuge",
    songNameOriginal: "Spirit's Refuge",
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'The City of Akaton',
    songNameOriginal: 'The City of Akaton',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'The Dragon Hoard',
    songNameOriginal: 'The Dragon Hoard',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'The Herbalist',
    songNameOriginal: 'The Herbalist',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Victorian Mystery',
    songNameOriginal: 'Victorian Mystery',
    composerName: 'Scrybe',
    sourceLink: 'https://www.scrybequill.com/',
    licenseLink: 'https://creativecommons.org/licenses/by/4.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 4.0 License',
  },

  {
    songNameScrybe: 'Wode',
    songNameOriginal: 'Wode',
    composerName: 'Ivan Duch',
    sourceLink: 'https://ivanduch.com/',
    licenseLink: 'https://ivanduch.com/licensing/',
    licenseText: "Licensed under Ivan Duch's Music License Agreement",
  },
  {
    songNameScrybe: 'Whispering Door',
    songNameOriginal: 'Whispering Door',
    composerName: 'z3r0',
    sourceLink: 'https://www.youtube.com/@z3r0CopyrightFreeMusic',
    licenseLink: 'https://creativecommons.org/licenses/by/3.0/',
    licenseText: 'Licensed under Creative Commons BY Attribution 3.0 License',
  },
  {
    songNameScrybe: 'Wonderlust',
    songNameOriginal: 'Cinematic Fantasy Dark',
    composerName: 'RomanSenykMusic',
    sourceLink: 'https://pixabay.com/users/romansenykmusic-11989248/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Curse of the Manor',
    songNameOriginal: 'Curse of the Manor',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Ghosties and Ghoulies',
    songNameOriginal: 'Ghosties and Ghoulies',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Let the Mystery Unfold',
    songNameOriginal: 'Let the Mystery Unfold',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Magic in the Air',
    songNameOriginal: 'Magic in the Air',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Midnight Coven',
    songNameOriginal: 'Midnight Coven',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'The Abandoned Manor',
    songNameOriginal: 'The Abandoned Manor',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'The Crystal Cave',
    songNameOriginal: 'The Crystal Cave',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'The Enchanted Kingdom',
    songNameOriginal: 'The Enchanted Kingdom',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'The Lights of the Village',
    songNameOriginal: 'The Lights of the Village',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'The Lowlands',
    songNameOriginal: 'The Lowlands',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'The Wishing Well',
    songNameOriginal: 'The Wishing Well',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Ways of the Wizard',
    songNameOriginal: 'Ways of the Wizard',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
  {
    songNameScrybe: 'Witches Cauldron',
    songNameOriginal: 'Witches Cauldron',
    composerName: 'Geoff Harvey',
    sourceLink: 'https://pixabay.com/users/geoffharvey-9096471/',
    licenseLink: 'https://pixabay.com/service/license-summary/',
    licenseText: 'Licensed under Pixabay Content License',
  },
];

function CreditPage() {
  window.scrollTo(0, 0);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const filteredSongs = songData.filter(
    (song) =>
      song.songNameScrybe.toLowerCase().includes(searchQuery.toLowerCase()) ||
      song.songNameOriginal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      song.composerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      song.licenseLink.toLowerCase().includes(searchQuery.toLowerCase()) ||
      song.licenseText.toLowerCase().includes(searchQuery.toLowerCase()) ||
      song.sourceLink.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Order by song name
  filteredSongs.sort((a, b) =>
    a.songNameScrybe.localeCompare(b.songNameScrybe)
  );

  const totalPages = Math.ceil(filteredSongs.length / itemsPerPage) || 1;

  // Ensure current page is valid
  useEffect(() => {
    const newTotalPages = Math.ceil(filteredSongs.length / itemsPerPage) || 1;
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  }, [filteredSongs, itemsPerPage]);

  const indexOfLastSong = currentPage * itemsPerPage;
  const indexOfFirstSong = indexOfLastSong - itemsPerPage;
  const currentSongs = filteredSongs.slice(indexOfFirstSong, indexOfLastSong);

  return (
    <div className="max-w-5xl px-8 sm:px-12 lg:px-16 pt-20 pb-4 sm:pb-12 lg:pb-16 md:pt-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut">
          Music Credit
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          Search by name, composer, and license type
        </p>
      </div>

      {/* Pagination Controls */}
      <div className="mx-auto pb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        {/* Search bar */}
        <div className="relative w-full sm:w-auto">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className="py-2 px-3 ps-11 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          />
          <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
            <svg
              className="shrink-0 w-4 h-4 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <path d="m21 21-4.3-4.3" />
            </svg>
          </div>
        </div>

        {/* Prev and Next Buttons for Larger Screens */}
        {totalPages > 1 && (
          <div className="hidden sm:inline-flex gap-x-2 justify-center w-full sm:w-auto">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            >
              {/* Prev Icon */}
              <svg
                className="shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Prev
            </button>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            >
              Next
              {/* Next Icon */}
              <svg
                className="shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </button>
          </div>
        )}
      </div>
      {/* End Pagination Controls */}

      {/* Songs */}
      <div className="grid grid-cols-1 gap-6 mx-auto">
        {currentSongs.map((song, index) => (
          <CreditSection
            key={index}
            songNameScrybe={song.songNameScrybe}
            songNameOriginal={song.songNameOriginal}
            composerName={song.composerName}
            sourceLink={song.sourceLink}
            licenseLink={song.licenseLink}
            licenseText={song.licenseText}
          />
        ))}
      </div>

      {/* Page Count */}
      {totalPages > 1 && (
        <div className="px-6 py-4 mt-4 hidden sm:flex justify-end">
          <div>
            <p className="text-sm text-gray-600 dark:text-neutral-400">
              Page{' '}
              <span className="font-semibold text-gray-800 dark:text-neutral-200">
                {currentPage}
              </span>{' '}
              of{' '}
              <span className="font-semibold text-gray-800 dark:text-neutral-200">
                {totalPages}
              </span>
            </p>
          </div>
        </div>
      )}

      {/* Placeholder when no results */}
      {filteredSongs.length === 0 && (
        <div className="max-w-2xl mx-auto mt-16 text-center flex flex-col items-center">
          <SpilledInkIcon className="w-16 h-16" aria-hidden="true" />
          <div className="font-inknut text-lg flex flex-col">
            No results found
          </div>
          Please adjust your search input.
        </div>
      )}

      {totalPages > 1 && (
        <div className="sm:hidden sticky bottom-0 w-full bg-white dark:bg-slate-900 py-4 mt-2 flex justify-between">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
          >
            <svg
              className="shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
            Prev
          </button>

          <div className="flex items-center">
            <div>
              <p className="text-sm text-gray-600 dark:text-neutral-400">
                Page{' '}
                <span className="font-semibold text-gray-800 dark:text-neutral-200">
                  {currentPage}
                </span>{' '}
                of{' '}
                <span className="font-semibold text-gray-800 dark:text-neutral-200">
                  {totalPages}
                </span>
              </p>
            </div>
          </div>

          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
          >
            Next
            <svg
              className="shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

export default CreditPage;
