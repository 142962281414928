import React from 'react';
import {
  FaXTwitter,
  FaYoutube,
  FaTiktok,
  FaInstagram,
  FaTwitch,
  FaDiscord,
  FaFacebook,
  FaPatreon,
  FaSpotify,
  FaBandcamp,
} from 'react-icons/fa6';

const PartnerCard = ({ logo, name, subtitle, link, socialLinks }) => {
  // Mapping of platform names to icon components and hover colors
  const iconComponents = {
    youtube: {
      icon: FaYoutube,
      hoverColor: 'hover:text-red-500',
    },
    twitter: {
      icon: FaXTwitter,
      hoverColor: 'hover:text-black',
    },
    instagram: {
      icon: FaInstagram,
      hoverColor: 'hover:text-pink-500',
    },
    twitch: {
      icon: FaTwitch,
      hoverColor: 'hover:text-purple-600',
    },
    discord: {
      icon: FaDiscord,
      hoverColor: 'hover:text-blue-500',
    },
    facebook: {
      icon: FaFacebook,
      hoverColor: 'hover:text-blue-700',
    },
    patreon: {
      icon: FaPatreon,
      hoverColor: 'hover:text-orange-500',
    },
    spotify: {
      icon: FaSpotify,
      hoverColor: 'hover:text-green-500',
    },
    bandcamp: {
      icon: FaBandcamp,
      hoverColor: 'hover:text-cyan-500',
    },

    // Handle TikTok separately due to special styling
  };

  return (
    <div className="flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 p-6 text-center transform transition duration-300 hover:scale-101 hover:shadow-md">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-20 h-20 rounded-full mb-4 mx-auto overflow-hidden"
      >
        <img src={logo} alt={name} className="w-full h-full object-cover" />
      </a>

      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
      >
        <h3 className="text-lg font-semibold dark:text-white">{name}</h3>
      </a>
      <p className="text-sm text-gray-600 dark:text-gray-400 uppercase">
        {subtitle}
      </p>
      <div className="flex justify-center space-x-4 mt-4">
        {Object.entries(socialLinks).map(([platform, url]) => {
          if (platform === 'tiktok') {
            return (
              <a
                key={platform}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="relative group"
              >
                {/* Blue shadow */}
                <FaTiktok className="absolute top-0 left-0 text-tiktokblue opacity-0 group-hover:opacity-100 -translate-x-px -translate-y-px" />
                {/* Red shadow */}
                <FaTiktok className="absolute top-0 left-0 text-tiktokred opacity-0 group-hover:opacity-100 translate-x-px translate-y-px" />
                {/* Main Icon */}
                <FaTiktok className="text-gray-600 dark:text-gray-400 hover:text-black relative z-10" />
              </a>
            );
          } else {
            const { icon: IconComponent, hoverColor } =
              iconComponents[platform];
            return (
              <a
                key={platform}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconComponent
                  className={`text-gray-600 dark:text-gray-400 ${hoverColor}`}
                />
              </a>
            );
          }
        })}
      </div>
    </div>
  );
};

export default PartnerCard;
