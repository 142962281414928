import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import { useState } from 'react';
import { FaRegCopy, FaCheck } from 'react-icons/fa';
import { FaXTwitter, FaYoutube } from 'react-icons/fa6';

import SHGT_Logo from '../assets/partner_logos/SHGT_Logo.jpg';
import Michael_Ghelfi_Logo from '../assets/partner_logos/Michael_Ghelfi_Studios_Logo.jpg';
import Supernova_Collective_Logo from '../assets/partner_logos/Supernova_Collective_Logo.jpg';
import Darkeport_Productions_Logo from '../assets/partner_logos/Darkeport_Productions_Logo.jpg';
import RogueMDF_Logo from '../assets/partner_logos/RogueMDF_Logo.jpg';
import The_Ugly_Goblin_Logo from '../assets/partner_logos/The_Ugly_Goblin_Logo.jpg';
import DM_Timothy_Logo from '../assets/partner_logos/DM_Timothy_Logo.jpg';
import Cantrips_Media_Logo from '../assets/partner_logos/Cantrips_Media_Logo.jpg';
import The_Usual_NPCs_Logo from '../assets/partner_logos/The_Usual_NPCs_Logo.jpg';

import PartnerCard from '../components/PartnerCard';

function PartnersPage() {
  // Scroll to top on load
  window.scrollTo(0, 0);

  const partners = [
    {
      name: 'Michael Ghelfi Studios',
      logo: Michael_Ghelfi_Logo,
      subtitle: 'RPG Ambiences and Music',
      link: 'https://www.michaelghelfistudios.com/',
      socialLinks: {
        youtube: 'https://www.youtube.com/@MichaelGhelfiStudios',
        twitter: 'https://www.x.com/MGhelfiStudios',
        // facebook: 'https://www.facebook.com/MichaelGhelfiStudios',
        twitch: 'https://www.twitch.tv/michaelghelfi',
        spotify:
          'https://open.spotify.com/intl-fr/artist/17njitvUNAIx81UoflmQyi?si=S84ABAGjRm6Z6EkSjsH1uw',
        bandcamp: 'https://michaelghelfi.bandcamp.com/',
        // patreon: 'https://www.patreon.com/MichaelGhelfi',
      },
    },
    {
      name: 'The Ugly Goblin',
      logo: The_Ugly_Goblin_Logo,
      subtitle: 'Content Creator',
      link: 'https://youtu.be/wFJWe4wHvv0?si=RgdrzfbyinFMcpnX&t=403',
      socialLinks: {
        youtube: 'https://www.youtube.com/@TheUglyGoblin',
        twitter: 'https://twitter.com/gabrielgalway',
        discord: 'https://discord.gg/4eybm9fgTU',
        patreon: 'https://www.patreon.com/TheUglyGoblin',
      },
    },
    {
      name: 'DM Timothy',
      logo: DM_Timothy_Logo,
      subtitle: 'Content Creator',
      link: 'https://dmtimothy.com/',
      socialLinks: {
        youtube: 'https://youtu.be/ShcBJymvM3A?si=O1-w7-zvjs_mxjPM&t=38',
        instagram: 'https://www.instagram.com/dm_timothy',
      },
    },
    {
      name: 'RogueMDF',
      logo: RogueMDF_Logo,
      subtitle: 'Content Creator',
      link: 'https://www.youtube.com/shorts/KTpaGM8riI8',
      socialLinks: {
        youtube: 'https://www.youtube.com/@RogueMDF',
        instagram: 'https://www.instagram.com/roguemdf_',
        tiktok: 'https://www.tiktok.com/@roguemdf',
        twitch: 'https://www.twitch.tv/roguemdf',
      },
    },
    {
      name: 'SHGT Productions',
      logo: SHGT_Logo,
      subtitle: 'Actual Play Series',
      link: 'https://www.shgtproductions.com/',
      socialLinks: {
        youtube: 'https://www.youtube.com/@SHGTP',
        twitter: 'https://www.x.com/SHGTProductions',
        instagram: 'https://www.instagram.com/shgt_productions/',
        // facebook: 'https://www.facebook.com/SHGTProductions',
        tiktok: 'https://tiktok.com/@shgt_productions',
        // twitch: 'https://www.twitch.tv/guardtowergames',
        discord: 'https://discord.gg/PtJdNNvEBV',
        // patreon: 'https://www.patreon.com/DCoG',
      },
    },
    {
      name: 'Cantrips Media',
      logo: Cantrips_Media_Logo,
      subtitle: 'Content Creator',
      link: 'https://cantripsmedia.com/',
      socialLinks: {
        youtube: 'https://youtu.be/h02nC9gO3H4?si=lHxS8bYXeLCJ-XR-',
        instagram: 'https://www.instagram.com/cantripsmedia',
        tiktok: 'https://www.tiktok.com/@cantripsmedia',
        discord: 'https://discord.gg/cAFCEw3dqR',
      },
    },
    {
      name: 'Supernova Collective',
      logo: Supernova_Collective_Logo,
      subtitle: 'RPG Music',
      link: 'https://supernovacollective.bandcamp.com/',
      socialLinks: {
        youtube: 'https://www.youtube.com/channel/UC1gloL1UH0OzTfzDzCscZdw',
        twitter: 'https://www.x.com/co_supernova',
        instagram: 'https://instagram.com/supernova_collective',
        // facebook: 'https://www.facebook.com/supernovae.collective',
        // twitch: 'https://www.twitch.tv/supernova_collectiv3',
        spotify:
          'https://open.spotify.com/artist/1vTQauAY1g9M9mP3sEhgjM?si=veKN7XSpQF2IkZLy0f7Kew',
        bandcamp: 'https://supernovacollective.bandcamp.com/',
      },
    },
    {
      name: 'Darkeport Productions',
      logo: Darkeport_Productions_Logo,
      subtitle: 'Actual Play Series',
      link: 'https://darkeport.com/',
      socialLinks: {
        youtube: 'https://www.youtube.com/@DarkeportProductions',
        twitter: 'https://x.com/darkeport',
        instagram: 'https://instagram.com/darkeport',
        facebook: 'https://www.facebook.com/Darkeport',
        discord: 'https://discord.gg/uscDEW43Ew',
      },
    },
    {
      name: 'The Usual NPCs',
      logo: The_Usual_NPCs_Logo,
      subtitle: 'Actual Play Series',
      link: 'https://www.youtube.com/@TheUsualNPCs',
      socialLinks: {
        youtube: 'https://www.youtube.com/@TheUsualNPCs',
        twitter: 'https://twitter.com/TheUsualNPCs',
        instagram: 'https://www.instagram.com/theusualnpcs',
        twitch: 'https://www.twitch.tv/theusualnpcs',
      },
    },
  ];

  return (
    <div className="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut">
          Partners
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-xs mx-auto">
          A bunch of cool people
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {partners.map((partner, index) => (
          <PartnerCard
            key={index}
            logo={partner.logo}
            name={partner.name}
            subtitle={partner.subtitle}
            link={partner.link}
            socialLinks={partner.socialLinks}
          />
        ))}
      </div>
    </div>
  );
}

export default PartnersPage;
