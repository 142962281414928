import './App.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import { HomePage } from './pages/HomePage';
import Pricing from './pages/PricingPage';
import Campaigns from './pages/Campaigns';
import CampaignPage from './pages/CampaignPage';
import SessionPage from './pages/SessionPage';
import TestPage from './pages/TestPage';
import ContactPage from './pages/ContactPage';
import FAQPage from './pages/FAQPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage';
import CreditPage from './pages/CreditPage';
import DevLogPage from './pages/DevLogPage';
import PartnersPage from './pages/PartnersPage';
import NotFoundPage from './pages/NotFoundPage';

import { SessionContextProvider } from './SessionContext';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <SessionContextProvider>
      <Router>
        <div className="App selection:bg-[rgba(211,211,211,0.5)]">
          <div className="min-h-screen">
          <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/campaigns/:campaignId" element={<CampaignPage />} />
              <Route
                path="/campaigns/:campaignId/:sessionId"
                element={<SessionPage />}
              />
              {/* <Route path='/test' element={<TestPage />} /> */}
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms-of-service" element={<TermsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/credit" element={<CreditPage />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/dev-log" element={<DevLogPage />} />
              <Route path="/partners" element={<PartnersPage />} />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </SessionContextProvider>
  );
}

export default App;
